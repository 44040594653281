import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '../../../../../image/remove.svg'
import AddIcon from '../../../../../image/plus.svg'
import Modify from '../../../../../image/modify.svg'

const VerifyStatus = ({ status, iconOnly }) => {
    return (
        <>
            {status === 'PASS' &&
                (iconOnly ? (
                    <Tooltip title="成功" placement="right">
                        <CheckIcon sx={{ color: 'primary.main' }} />
                    </Tooltip>
                ) : (
                    <Box display="flex" alignItems="center" gap={0.5}>
                        <CheckIcon sx={{ color: 'primary.main' }} />
                        <Typography variant="body2">成功</Typography>
                    </Box>
                ))}
            {status === 'CLEARANCE_RECORD_ERROR' &&
                (iconOnly ? (
                    <Tooltip title="Clearance record error" placement="right">
                        <CloseIcon color="error" />
                    </Tooltip>
                ) : (
                    <Box display="flex" alignItems="center" gap={0.5}>
                        <CloseIcon color="error" />
                        <Typography variant="body2">Clearance record error</Typography>
                    </Box>
                ))}
            {/* TODO check for the status below */}
            {status === 'REMOVED' && (
                <Tooltip title="移除" placement="right">
                    <img width="30" alt="remove" src={RemoveIcon} />
                </Tooltip>
            )}
            {status === 'ADDED' && (
                <Tooltip title="增加" placement="right">
                    <img width="30" alt="add" src={AddIcon} />
                </Tooltip>
            )}
            {status === 'MODIFIED' && (
                <Tooltip title="修改" placement="right">
                    <img height="30" alt="result" src={Modify} />
                </Tooltip>
            )}
        </>
    )
}

VerifyStatus.propTypes = {
    status: PropTypes.string.isRequired,
    iconOnly: PropTypes.bool.isRequired,
}

export default VerifyStatus
