import React from 'react'
import { AppBar, Toolbar, Box, Typography } from '@mui/material'
import dgmLogo from '../../image/DGM LOGO.png'
import logo from '../../image/ITM-Logo-white.png'

const Navbar = () => {
    return (
        <AppBar position="static" color="primary" elevation={0}>
            <Toolbar>
                <Box display="flex" alignItems="flex-end" gap={2}>
                    <Box component="img" mr={-1} src={dgmLogo} width={104} height={55.5} />
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, fontFamily: 'myriad-variable, sans-serif', alignSelf: 'center' }}
                    >
                        X
                    </Typography>
                    <Box component="img" src={logo} width={130} height={60.74} />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
