import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    padding: '8px 48px',
                },
                sizeSmall: {
                    padding: '6px 24px',
                    fontSize: '1rem',
                },
            },
        },
    },
    palette: {
        common: {
            black: '#333333',
            white: '#FFFFFF',
        },
        primary: {
            main: '#F5B919',
            dark: '#AF7400',
            contrastText: '#FFFFFF',
        },
        background: {
            paper: '#FFFFFF',
            default: '#F5B91966',
        },
        text: {
            primary: '#333333',
            secondary: '#5F5F5F',
        },
    },
    typography: {
        fontFamily: "'Noto Sans TC', sans-serif",
        serifTitle: {
            fontFamily: "'minion-pro-display', serif",
            fontWeight: 700,
            fontSize: '3.5rem',
            lineHeight: 1.2,
            letterSpacing: '0.02em',
        },
        serifNumTitle: {
            fontFamily: "'minion-pro-display', serif",
            fontWeight: 400,
            fontSize: '2.8rem',
            lineHeight: 1.167,
            letterSpacing: '0em',
        },
        serifNumLargeTitle: {
            fontFamily: "'minion-pro-display', serif",
            fontWeight: 400,
            fontSize: '4.2rem',
            lineHeight: 1.2,
            letterSpacing: '-0.00833em',
        },
        stepLabel: {
            fontFamily: "'Noto Serif TC', serif",
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.08em',
        },
        h1: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 300,
            fontSize: '6rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em',
        },
        h2: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 300,
            fontSize: '3.75rem',
            lineHeight: 1.2,
            letterSpacing: '-0.00833em',
        },
        h3: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 400,
            fontSize: '3rem',
            lineHeight: 1.167,
            letterSpacing: '0em',
        },
        h4: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 400,
            fontSize: '2.125rem',
            lineHeight: 1.235,
            letterSpacing: '0.00735em',
        },
        h5: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.334,
            letterSpacing: '0em',
        },
        h6: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 400,
            fontSize: '1.25rem',
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
        },
        body1: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
        },
        body2: {
            fontFamily: "'Noto Sans TC', sans-serif",
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.02em',
        },
        button: {
            fontFamily: 'myriad-variable, sans-serif',
            fontWeight: 500,
            fontSize: '1rem', // not working
            lineHeight: 1.75,
            letterSpacing: '0.05em',
            fontVariationSettings: "'wdth' 110, 'wght' 600",
        },
    },
    mixins: {
        toolbar: {
            minHeight: 64,
            '@media (min-width: 0px)': {
                '@media (orientation: landscape)': {
                    minHeight: 56,
                },
            },
            '@media (min-width: 600px)': {
                minHeight: 72,
            },
        },
    },
})
