import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Typography, styled, InputBase, Button, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import ProgressBar from './StepperWithProgressBar/ProgressBar'
import { fetchResultWithId } from '../../../api/resultApi'

const CustomInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 50,
        backgroundColor: theme.palette.common.white + '80',
        border: '1px solid',
        borderColor: '#00000066',
        fontSize: 14,
        padding: '10px 30px',
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            border: '2px solid',
            borderColor: theme.palette.primary.main,
        },
    },
}))

const Submission = ({ setData, setProgressDone, onApiFail }) => {
    const { oilId } = useParams()
    const [inputValue, setInputValue] = useState('')
    const [isProgressing, setIsProgressing] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onInputChange = (e) => {
        setInputValue(e.currentTarget.value?.toLocaleUpperCase())
    }

    const onBackropClose = () => {
        setTimeout(() => {
            setIsProgressing(false)
        }, 400)
    }

    const onSubmit = (oilId) => {
        setIsProgressing(true)
        setInputValue('')
        setData(null)

        fetchResultWithId(oilId)
            .then((res) => {
                setData(res.data)
            })
            .catch(() => {
                setIsProgressing(false)
                onApiFail()
            })
    }

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [inputValue])

    useEffect(() => {
        if (oilId && oilId !== '') {
            onSubmit(oilId)
        }
    }, [])

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ textAlign: 'center' }}>
                <Typography variant="serifTitle">YOUR CODE</Typography>
                <Typography variant="h6" sx={{ mt: 1, fontWeight: 400, letterSpacing: '0.09em' }}>
                    輸入您的編碼，查閱區塊鏈
                </Typography>
                <Box width="100%" textAlign="-webkit-center" mt={5}>
                    <Box sx={{ width: { lg: '35%', md: '45%', sm: '80%', xs: '100%' } }}>
                        <CustomInput
                            fullWidth
                            placeholder="TWILAT223-E076-07"
                            value={inputValue}
                            onChange={onInputChange}
                        />
                    </Box>
                </Box>
                <Button variant="contained" disabled={disabled} sx={{ mt: 5 }} onClick={() => onSubmit(inputValue)}>
                    <Typography>SUBMIT</Typography>
                </Button>
                <Box mt={6}>
                    <Typography variant="body1">
                        區塊鏈履歷存證與查驗系統：由ITM提供
                        <br />
                        區塊鏈系統：由ITM提供
                    </Typography>
                </Box>
                <Stack spacing={1} mt={4} maxWidth={700}>
                    <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
                        Verifier of circularity and Sustainability：ISCC
                    </Typography>
                    <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
                        依照國際可持續性和碳認證 (ISCC PLUS) 的規範，永瑞實業與 ITM 合作，利用先進的區塊鏈技術 (BNS) 和
                        dNFT，對廢食用油進行蒐集、精煉後的生質柴油進行履歷追蹤和存證。
                    </Typography>
                    <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
                        此外，ISCC PLUS
                        認證確保了整個供應鏈的可持續性和質量，並提供了從包裝到紡織品的各種應用中使用的聚合物和生物複合材料的認證。
                    </Typography>
                </Stack>
                <Stack spacing={1} mt={4} maxWidth={700}>
                    <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
                        通過 ITM 提供的 Blockchain Notary Service (BNS) 和 dNFT
                        技術，永瑞實業可以確保每一批生質柴油的品質和來源都被確切記錄和驗證。
                    </Typography>
                    <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
                        所有的資料都存儲在公有區塊鏈上，確保了透明度和可追溯性。任何人都可以在此系統上查看和驗證生質柴油的生產和供應鏈資訊，確保沒有任何秘密。
                    </Typography>
                    <Typography variant="body2" color="text.secondary" lineHeight={1.5}>
                        此外，該系統還支持環保和可持續性，並符合國際認證標準。透過 ITM 結合 IoT
                        技術，可以將油品精煉過程中的關鍵數據進行存證紀錄，為未來石油公司建立合規標準，確保其生產過程的透明度和可靠性。
                    </Typography>
                </Stack>
            </Box>
            <Backdrop
                sx={{
                    zIndex: 1,
                    color: 'primary',
                    backgroundColor: 'rgb(0, 0, 0, 0.8)',
                }}
                open={isProgressing}
                transitionDuration={{ exit: 500 }}
            >
                <Box mt={3} sx={{ maxWidth: '65%' }}>
                    <ProgressBar
                        onBackropClose={onBackropClose}
                        setProgressDone={setProgressDone}
                        isProgressing={isProgressing}
                    />
                </Box>
            </Backdrop>
        </Box>
    )
}

Submission.propTypes = {
    setData: PropTypes.func.isRequired,
    setProgressDone: PropTypes.func.isRequired,
    onApiFail: PropTypes.func.isRequired,
}

export default Submission
