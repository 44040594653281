import React from 'react'
import PropTypes from 'prop-types'
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Button,
    Tooltip,
    Typography,
    CircularProgress,
    styled,
    IconButton,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import GavelIcon from '@mui/icons-material/Gavel'
import GetAppIcon from '@mui/icons-material/GetApp'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import VerifyStatus from './VerifyStatus'
import proofExistenceType from '../../../../../constant/proofExistenceType'
import verifyProcesStatusConstant from '../../../../../constant/verifyProcessStatusConstant'
import {
    getAttesterAddress,
    getAttestType,
    renderCmd,
    renderExistence,
    renderTime,
} from '../../../../../util/stringUtil'

const Container = styled(Box)(({ theme }) => ({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    overflowY: 'auto',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '85%',
    [theme.breakpoints.down('sm')]: {
        width: '80%',
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
        maxHeight: '75%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '60%',
        maxWidth: 900,
        maxHeight: '85%',
    },
}))

const MoreInfoModal = ({ moreInfo, close, handleVerifyRawData, handleDownloadRawData }) => {
    // get data
    const attesterAddress = getAttesterAddress(moreInfo.cmd)
    // TODO Get certification info from verify result
    const attesterInfo = '永瑞實業股份有限公司'
    // const attesterInfo = getMIHAttesterInfo(moreInfo.cmd)

    return (
        <Container id="more-info-modal" sx={{ borderRadius: 2 }}>
            <Box mt={2} display="flex" justifyContent="space-between">
                <Typography variant="h6" color="textPrimary">
                    更多資訊
                </Typography>
                <IconButton onClick={close}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
            {moreInfo.existenceType === proofExistenceType.exist && (
                <Box mt={1} mb={3}>
                    <Box display="flex" alignItems="center" gap={2}>
                        {!moreInfo.rawDataVerifyResult && (
                            <Button
                                startIcon={
                                    moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending ? (
                                        <CircularProgress size={18} sx={{ color: 'white' }} />
                                    ) : (
                                        <GavelIcon />
                                    )
                                }
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => handleVerifyRawData(moreInfo)}
                            >
                                驗證原始資料
                            </Button>
                        )}
                        <Button
                            startIcon={<GetAppIcon />}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleDownloadRawData(moreInfo)}
                        >
                            下載原始資料
                        </Button>
                    </Box>
                </Box>
            )}
            {(moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending ||
                moreInfo.verifyProcessStatus === verifyProcesStatusConstant.complete) && (
                <Box mb={4}>
                    <Typography variant="body1" color="textPrimary">
                        原始資料驗證結果
                    </Typography>
                    {moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending && (
                        <Box py={1} display="flex" justifyContent="center">
                            <CircularProgress size={24} />
                        </Box>
                    )}
                    {moreInfo.verifyProcessStatus === verifyProcesStatusConstant.complete && (
                        <TableContainer component={Paper} elevation={1} sx={{ mt: 2 }}>
                            <Table>
                                <TableBody>
                                    <TableRow id="raw-data-content-row">
                                        <TableCell width="20%">原始資料</TableCell>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                                            >
                                                {renderCmd(moreInfo.cmd)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow id="verify-result-row">
                                        <TableCell width="20%">原始資料驗證狀態</TableCell>
                                        <TableCell>
                                            {moreInfo.rawDataVerifyResult.verifyResult === 'PASS' && (
                                                <Box display="flex" alignItems="center">
                                                    <CheckIcon sx={{ color: 'primary.main' }} />
                                                    <Typography variant="body2">驗證成功</Typography>
                                                </Box>
                                            )}
                                            {moreInfo.rawDataVerifyResult.verifyResult === 'FAILED' && (
                                                <Box display="flex" alignItems="center">
                                                    <CloseIcon color="error" />
                                                    <Typography variant="body2">驗證失敗</Typography>
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            )}
            <Typography variant="body1" color="textPrimary">
                詳細資訊
            </Typography>
            <TableContainer component={Paper} elevation={1} sx={{ mt: 2 }}>
                <Table>
                    <TableBody>
                        {moreInfo.cmd && moreInfo.cmd.includes('type') && (
                            <TableRow id="type-row">
                                <TableCell width="20%">類別</TableCell>
                                <TableCell>{getAttestType(moreInfo.cmd)}</TableCell>
                            </TableRow>
                        )}
                        {moreInfo.existenceType === proofExistenceType.exist && (
                            <>
                                {attesterAddress && attesterAddress !== '' && (
                                    <TableRow id="attester-did-row">
                                        <TableCell width="20%">存證者錢包地址</TableCell>
                                        <TableCell>{attesterAddress}</TableCell>
                                    </TableRow>
                                )}
                                {attesterInfo && attesterInfo !== '' && (
                                    <TableRow id="attester-certificated-info-row">
                                        <TableCell width="20%">存證者實名認證資訊</TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center" gap={0.5}>
                                                <Typography variant="body2">{attesterInfo}</Typography>
                                                <CheckCircleIcon fontSize="small" sx={{ color: 'primary.main' }} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )}
                        <TableRow id="blockchain-detail-row">
                            <TableCell width="20%">區塊鏈詳情</TableCell>
                            <TableCell>
                                {moreInfo.txHash !== null ? (
                                    <Tooltip title="點擊數字前往區塊鏈瀏覽器確認鏈上紀錄" placement="right">
                                        <Typography
                                            id="more-info-co-link"
                                            component="a"
                                            variant="body2"
                                            href={`${moreInfo.txHashBaseUrl}/${moreInfo.txHash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            fontWeight={500}
                                            sx={{ color: 'primary.main', textDecoration: 'none' }}
                                        >
                                            {moreInfo.clearanceOrder}
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <>{moreInfo.clearanceOrder}</>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow id="index-value-row">
                            <TableCell width="20%">Index Value</TableCell>
                            <TableCell>{moreInfo.indexValue}</TableCell>
                        </TableRow>
                        <TableRow id="existence-row">
                            <TableCell width="20%">存在性</TableCell>
                            <TableCell>{renderExistence(moreInfo.existenceType)}</TableCell>
                        </TableRow>
                        <TableRow id="verify-status-row">
                            <TableCell width="20%">驗證狀態</TableCell>
                            <TableCell>
                                <VerifyStatus status={moreInfo.proofExistStatus} iconOnly={false} />
                            </TableCell>
                        </TableRow>
                        {moreInfo.existenceType === proofExistenceType.exist && (
                            <>
                                <TableRow id="attestation-time-row">
                                    <TableCell width="20%">存證時間</TableCell>
                                    <TableCell>{renderTime(moreInfo.receiptTimestamp)}</TableCell>
                                </TableRow>
                                {moreInfo.merkleProofRootHash !== null && (
                                    <TableRow id="root-hash-row">
                                        <TableCell width="20%">Root Hash</TableCell>
                                        <TableCell>{moreInfo.merkleProofRootHash}</TableCell>
                                    </TableRow>
                                )}
                                <TableRow id="attested-fields-row">
                                    <TableCell width="20%">存證資訊欄</TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                                        >
                                            {renderCmd(moreInfo.cmd)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button id="more-info-close-btn" variant="contained" onClick={close} size="small">
                    關閉
                </Button>
            </Box>
        </Container>
    )
}

MoreInfoModal.propTypes = {
    moreInfo: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
}

export default MoreInfoModal
