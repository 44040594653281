import React from 'react'
import PropTypes from 'prop-types'
import { DetailCard, DetailCardContent, DetailRowBox } from './VerifyResult'
import { Typography } from '@mui/material'
import nftLogo from '../../../image/nft-logo.jpg'

const NFTInfoBox = ({ data }) => {
    return (
        <DetailRowBox>
            <img style={{ width: 350, margin: 16 }} src={nftLogo} />
            <DetailCard variant="outlined">
                <DetailCardContent sx={{ ml: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 500, wordBreak: 'break-all' }}>
                        dNFT系列代號：DGM-TW00001
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500, wordBreak: 'break-all' }}>
                        dNFT累積收UCO數量：1000kg
                    </Typography>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                        dNFT ID: {data?.tokenID}
                    </Typography>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                        持有者名稱：David Lo
                    </Typography>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                        持有者錢包地址：0x481da9e373d2493ec14de3c101743c8825e11ce8
                    </Typography>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                        持有者信箱：dgm-demo-admin@dgm.itm.com
                    </Typography>
                </DetailCardContent>
            </DetailCard>
        </DetailRowBox>
    )
}

NFTInfoBox.propTypes = {
    data: PropTypes.object.isRequired,
}

export default NFTInfoBox
