import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { styled, Box, Typography, Divider, Card, CardMedia, CardContent, Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import NFTInfoBox from './NFTInfoBox'

import itmLogo from '../../../image/ITM-Logo.png'
import oilPhoto from '../../../image/TWILAT223-E076-07.jpg'
import usedCookingOilPhoto from '../../../image/used_cooking_oil.webp'

import dgmLogo from '../../../image/DGM LOGO-black.png'
import { renderTime } from '../../../util/stringUtil'

const numberTitleWidth = 120
const numberTitleHeight = 120

const VerticalDecoBar = styled(Box)(({ theme }) => ({
    width: 26,
    height: '100%',
    background: theme.palette.primary.main,
    borderRadius: 20,
    position: 'absolute',
    opacity: 0.9,
    top: 0,
    left: 47,
    zIndex: -1,
}))

export const DetailRowBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}))

export const DetailCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    width: 620,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white + '80',
}))

export const DetailCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
})

const VerifyResult = ({ data }) => {
    const etherscanUrlPrefix = 'https://goerli.etherscan.io'
    const baseUrl = window.location.origin
    return (
        <Box gap={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5">區塊鏈驗證結果</Typography>
            <Box mt={1} gap={2} display="flex" alignItems="center">
                <Typography variant="h6">CODE：{data.treeCode?.toLocaleUpperCase()}</Typography>
                <Typography variant="h6">/</Typography>
                <Box gap={1} display="flex" alignItems="center">
                    <Typography variant="h6">整體驗證結果：</Typography>
                    <CheckIcon />
                    <Typography variant="h6">驗證成功</Typography>
                </Box>
                <Typography variant="h6">/</Typography>
                <Typography variant="h6">驗證時間：{renderTime(moment.now())}</Typography>
            </Box>
            {/* TODO extract detail cards? */}
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                m={2}
                mt={6}
                sx={{ position: 'relative' }}
            >
                <NFTInfoBox data={data} />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                m={2}
                mt={6}
                sx={{ position: 'relative' }}
            >
                <VerticalDecoBar />
                <DetailRowBox>
                    {/* TODO extract number title */}
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: numberTitleWidth,
                            height: numberTitleHeight,
                            borderRadius: '50%',
                            border: '1px solid black',
                        }}
                    >
                        <Typography variant="serifNumLargeTitle" sx={{ fontWeight: 'bold' }}>
                            01
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    {/* TODO extract card */}
                    <DetailCard variant="outlined">
                        <CardMedia component="img" sx={{ width: 160, m: 2 }} src={oilPhoto} />
                        <DetailCardContent sx={{ ml: 2 }}>
                            <Typography variant="body1">單位：胖老爹美式炸雞 師大店</Typography>
                            <Typography variant="body1">交易地點：1000032 台北市中正區辛亥路一段 21 號</Typography>
                            <Typography variant="body1">交易時間：2023/09/10</Typography>
                            <Typography variant="body1">交易負責專員：沈鮮陞</Typography>
                            <Typography variant="body1">交易數量：25公升</Typography>
                            <Typography variant="body1">交易單位價格：15元</Typography>
                            <Typography variant="body1">交易總價格：375元 新台幣</Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
                <Divider orientation="vertical" sx={{ ml: 7.5, height: 100, borderColor: 'common.black' }} />
                <DetailRowBox>
                    {/* TODO extract number title */}
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: numberTitleWidth, height: numberTitleHeight, borderRadius: '50%' }}
                    >
                        <Typography variant="serifNumTitle" sx={{ fontWeight: 'bold' }}>
                            02
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    {/* TODO extract card */}
                    <DetailCard variant="outlined">
                        <CardMedia component="img" sx={{ width: 160, m: 2 }} src={usedCookingOilPhoto} />
                        <DetailCardContent sx={{ ml: 2 }}>
                            <Typography variant="body1">單位：肯德基 台大卓越餐廳</Typography>
                            <Typography variant="body1">
                                交易地點：106 台北市大安區羅斯福路四段1號（卓越大樓2樓櫃位）
                            </Typography>
                            <Typography variant="body1">交易時間：2023/09/11</Typography>
                            <Typography variant="body1">交易負責專員：沈鮮陞</Typography>
                            <Typography variant="body1">交易數量：30公升</Typography>
                            <Typography variant="body1">交易單位價格：16元</Typography>
                            <Typography variant="body1">交易總價格：480元 新台幣</Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
                <Divider orientation="vertical" sx={{ ml: 7.5, height: 100, borderColor: 'common.black' }} />
                <DetailRowBox>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: numberTitleWidth, height: numberTitleHeight, borderRadius: '50%' }}
                    >
                        <Typography variant="serifNumTitle" sx={{ fontWeight: 'bold' }}>
                            03
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined">
                        {/* TODO add black bg logo */}
                        <CardMedia component="img" sx={{ ml: 3, width: 161, height: 52 }} src={dgmLogo} />
                        <DetailCardContent sx={{ ml: 4 }}>
                            <Typography variant="body1">紀錄單位：永瑞實業</Typography>
                            <Typography variant="body1">紀錄時間：2023/09/11</Typography>
                            <Typography variant="body1">紀錄地點：82447 高雄市燕巢區中民路 763 號</Typography>
                            <Typography variant="body1">
                                認證機構：ISCC（International Sustainability & Carbon Certification）
                            </Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
                <Divider orientation="vertical" sx={{ ml: 7.5, height: 100, borderColor: 'common.black' }} />
                <DetailRowBox>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: numberTitleWidth, height: numberTitleHeight, borderRadius: '50%' }}
                    >
                        <Typography variant="serifNumTitle" sx={{ fontWeight: 'bold' }}>
                            04
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined">
                        <CardMedia component="img" sx={{ ml: 4, width: 150, height: 70 }} src={itmLogo} />
                        <DetailCardContent sx={{ ml: 4 }}>
                            <Typography variant="body1">上鏈單位：{data.clearanceOrg}</Typography>
                            <Box display="flex">
                                <Typography variant="body1">NFT 資訊：</Typography>
                                <Typography
                                    component="a"
                                    href={`${etherscanUrlPrefix}/tx/${data.txHash}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="body1"
                                    color="primary.main"
                                >
                                    查看鏈上資訊
                                </Typography>
                            </Box>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                Token ID：{data.tokenID}
                            </Typography>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                Token ID(10進制)：{BigInt('0x' + data.tokenID, 16).toString()}
                            </Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
            </Box>
            <Button
                variant="contained"
                color="primary"
                href={`${baseUrl}/nft-history/${data.tokenID}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ my: 5 }}
            >
                驗證詳情
            </Button>
        </Box>
    )
}

VerifyResult.propTypes = {
    data: PropTypes.object.isRequired,
}

export default VerifyResult
