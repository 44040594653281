import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { TableRow, TableCell, Box, Typography, IconButton, CircularProgress } from '@mui/material'
import GavelIcon from '@mui/icons-material/Gavel'
import GetAppIcon from '@mui/icons-material/GetApp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorCircleIcon from '@mui/icons-material/Error'

import VerifyStatus from './VerifyStatus'
import proofExistenceType from '../../../../../constant/proofExistenceType'
import verifyProcessStatusConstant from '../../../../../constant/verifyProcessStatusConstant'
import { getAttesterAddress, getAttestType, renderTime } from '../../../../../util/stringUtil'

const DataRow = ({ data, renderMoreInfo, handleVerifyRawData, handleDownloadRawData, renderClearanceOrder }) => {
    const renderCOAndLimitLengthIndexValue = (data) => {
        const iv = data.indexValue
        return (
            <>
                <TableCell align="left">
                    <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                    <Typography variant="body2">{iv}</Typography>
                </TableCell>
            </>
        )
    }

    // get data
    const attesterAddress = getAttesterAddress(data.cmd)
    const attestType = getAttestType(data.cmd)
    // TODO Get certification from verify result object
    const attesterInfo = '永瑞實業股份有限公司'
    // const attesterInfo = getMIHAttesterInfo(data.cmd)

    return (
        <TableRow id="verify-list-result-row" key={uuidv4()}>
            <TableCell align="left">
                <Typography variant="body2">
                    {data.existenceType === proofExistenceType.exist && renderTime(data.receiptTimestamp)}
                </Typography>
                <Typography variant="body2">{data.existenceType === proofExistenceType.exist && attestType}</Typography>
            </TableCell>
            <TableCell align="left">
                {data.existenceType === proofExistenceType.exist && (
                    <>
                        {<Typography variant="body2">{attesterAddress ? attesterAddress : ''}</Typography>}
                        <Box display="flex" alignItems="center" gap={0.5} mt={0.5}>
                            永瑞實業股份有限公司
                            {attesterInfo ? (
                                <CheckCircleIcon fontSize="small" sx={{ color: 'primary.main' }} />
                            ) : (
                                <ErrorCircleIcon fontSize="small" sx={{ color: 'primary.main' }} />
                            )}
                        </Box>
                    </>
                )}
            </TableCell>
            {renderCOAndLimitLengthIndexValue(data)}
            <TableCell align="center">
                <VerifyStatus status={data.proofExistStatus} iconOnly={true} />
            </TableCell>
            <TableCell align="center">
                {data.existenceType === proofExistenceType.exist && (
                    <>
                        {data.verifyProcessStatus === verifyProcessStatusConstant.pending && (
                            <CircularProgress size={24} />
                        )}
                        {data.verifyProcessStatus === verifyProcessStatusConstant.complete && (
                            <VerifyStatus status={data.rawDataVerifyResult.verifyResult} iconOnly={true} />
                        )}
                        {!data.verifyProcessStatus && (
                            <IconButton onClick={() => handleVerifyRawData(data)}>
                                <GavelIcon fontSize="small" />
                            </IconButton>
                        )}
                    </>
                )}
            </TableCell>
            <TableCell align="center">
                {data.existenceType === proofExistenceType.exist && (
                    <IconButton onClick={() => handleDownloadRawData(data)}>
                        <GetAppIcon />
                    </IconButton>
                )}
            </TableCell>
            <TableCell align="center">{renderMoreInfo(data)}</TableCell>
        </TableRow>
    )
}

DataRow.propTypes = {
    data: PropTypes.object.isRequired,
    renderMoreInfo: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
    renderClearanceOrder: PropTypes.func.isRequired,
}

export default DataRow
